/* You can add global styles to this file, and also import other style files */
@import 'fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Rich-Text-Editor styles */
@import './node_modules/suneditor/dist/css/suneditor.min';
// Override sun-editor defaults to match our needs
// Important: This class needs to be applied to the richtext control's content area
//            in edit-mode and the html-placeholder control's content area in viewer
//            mode. Otherwise the styles will differ and the content won't look alike.
.sun-editor-editable-customizations {
  padding: 0px;
  margin: 0px;

  p {
    margin: 0px;
  }

  ul,
  ol {
    padding-left: 20px;
    padding-right: 0;
    line-height: 1.6em;
    margin: 0 0 0 12px;
    letter-spacing: 0em;

    li {
      margin: 0;
    }
  }

  // list-style-type rules needed to be copied from original (.sun-editor-editable)
  // in order to ensure the same appereance in viewer-mode where the editor styles don't apply
  ul {
    list-style: disc;
    list-style-type: disc;
  }

  ul ul {
    list-style: circle;
    list-style-type: circle;
  }

  ul ul ul {
    list-style: square;
    list-style-type: square;
  }

  ol {
    list-style: decimal;
    list-style-type: decimal;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:root {
  --bdazzled-blue: #3c5793;
  --bdazzled-blue-opacity-03: rgba(60, 87, 147, 0.3);
  --font-color-tile-back: #b4c4e6;
  --nobel: #b3b3b3;
  --quill-gray: #d2d2d2;
  --white: #ffffff;

  --font-size-title: clamp(1.5rem, 6vw, 100px);
  --font-size-title-smaller: clamp(28px, 2.6vw, 50px);
  --font-size-sub-title: clamp(1rem, 2.2vw, 28px);
  --font-size-text: clamp(0.7rem, 1.3vw, 24px);
  --font-size-small-text: clamp(0.7rem, 1.2vw, 20px);
  --font-size-large-number: clamp(5rem, 34vw, 540px);
  --font-size-subheading: clamp(1.2rem, 2.8vw, 42px);
  --font-size-large-title: clamp(2rem, 7.5vw, 120px);
  --font-size-medium-title: clamp(1.3rem, 4.2vw, 70px);
  --font-size-small-title: clamp(1rem, 1.8vw, 30px);

  --font-family-helvetica: 'Helvetica Neue Regular'; // Siehe https://fonts.google.com/specimen/Open+Sans

  //
  --max-container-width: 1700px;
  --global-side-padding: 60px;
  --global-top-bottom-padding: 32px;

  --max-content-width: 3840px; // 4k width
  --max-header-width-presentation-mode: 1600px;
  --separator-line-color: rgb(215, 215, 215); // rgb(210, 210, 210);

  --rh-blue: #3d5996;
  // --rh-media-filter-area-blue: #5571ac;
  --rh-blue-edit-mode: rgb(158 178 221);
  --rh-blue-hover: #f1f5fc69;
  --rh-tools-color-disabled: #7b8293;
  --rh-white: #fff;
  --rh-white-slightly-opaque: rgba(255, 255, 255, 0.5);
  --rh-red: #b30000;

  --rh-placeholder-background: #f9f9f9;

  --rh-header-height: 80px;
  --rh-content-height: calc(100vh - var(--rh-header-height));

  --rh-header-border-width: 1px;

  --rh-ok-button-background: #7fa650;
  --rh-ok-button-hover-background: #adcb6e;
  --rh-cancel-button-background: #5c78b5;
  --rh-cancel-button-hover-background: #90a5d3;

  --rh-dialog-background: #3d5996; // #3d5996;
  --rh-dialog-backdrop: #3c588f66; // 60% transparency  #c4cddfb3; // #3d5996b3; // 30% transparency
  --rh-dialog-backdrop-dark: rgba(51, 51, 51, 0.9); //
  --rh-dark-dialog-background: #474747;

  font-family: var(--font-family-helvetica);
  background-color: rgb(240, 240, 240);
}

.custom-overlay-panel {
  background: var(--rh-dialog-backdrop);
  z-index: 111; // Has to be higher than e.g. the regular sticky header

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.custom-overlay-panel-dark {
  background: var(--rh-dialog-backdrop-dark);
  z-index: 111; // Has to be higher than e.g. the regular sticky header

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.custom-snackbar-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  margin: 0 auto;
  max-height: 100px;
  background: transparent;
  z-index: 10000;
}

.zoom-backdrop {
  background-color: white;
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

// custom loading spinner
.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.loading-spinner-container-background {
  background-color: rgba(0, 0, 0, 0.1);
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

// Common template styles
.top-bottom-padding-template {
  padding-top: var(--global-top-bottom-padding);
  padding-bottom: var(--global-top-bottom-padding);
}
