.reveal {
  margin: 0;
  padding: 0;
}

.reveal .slides {
  position: relative;
}

.reveal .slides section {
  height: 100%;
}

/* We need to explicitly set the progress-bar & controls position since 
   we add additional content (a header) above to the .reveal container 
   which pushes down the .reveal content. Therefore the position of the 
   progress-bar & the controls are positioned 'fixed' */
.reveal .progress {
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--rh-blue);
  height: 8px;
  position: fixed;
  bottom: 0;
}

.reveal .controls {
  position: fixed;
}

.reveal .controls .navigate-left .controls-arrow,
.reveal .controls .navigate-right .controls-arrow {
  color: var(--rh-blue);;
}

.reveal .controls .navigate-left,
.reveal .controls .navigate-right {
  background-color: white;
  border-radius: 5px;
  border: none;
  padding: 5px;
}